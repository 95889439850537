
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { PropType } from "vue";
import { Vue, prop, Options } from "vue-class-component";

class Props {
  dataSelect = prop<any[]>({
    default: [],
    type: Array
  });
  onOpenSelect = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onCloseSelect = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onChooseSelect = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  value = prop<string>({
    default: ""
  });
  isOpenMenu = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  longTrim = prop<number>({
    default: 50,
    type: Number
  });
  isError = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShowDefault = prop<boolean>({
    default: true,
    type: Boolean
  });
  heightOptions = prop<string>({
    default: "200px",
    type: String
  });
  isDefaultBlack = prop<boolean>({
    default: false,
    type: Boolean
  });
  textBold = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  isFirstInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  isAddableListNumber = prop<boolean>({
    default: false,
    type: Boolean
  });
  customClass = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  directives: {
    "click-outside": {
      beforeMount: (el: any, binding: any) => {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: (el: any) => {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Select extends Vue.with(Props) {
  mounted() {
    if (this.isFirstInput) {
      const pseudoInput: any = this.$refs.firstPseudoInput;
      pseudoInput?.focus();
    }
  }

  beforeMount() {
    this.assignNewDataSelect();
  }

  isValue(data: Array<any>, value: string) {
    const val = data.find(e => {
      return value === e.value || value === e.name;
    });
    return val?.name || "";
  }
  ellipsisValue(value: string) {
    return ellipsisString(value, this.longTrim);
  }
  isSelectOpen = false;
  openSelect() {
    if (!this.isDisabled) {
      const pseudoInput: any = this.$refs?.pseudoInput;
      pseudoInput.focus();
      this.selectedIndex = this.dataSelect?.findIndex(
        (item: any) => item.name === this.value || item.value === this.value
      );
      this.isSelectOpen = true;
      this.onOpenSelect();
    }
  }
  closeSelect() {
    if (!this.isDisabled) {
      const pseudoInput: any = this.$refs?.pseudoInput;
      pseudoInput.blur();
      this.isSelectOpen = false;
    }
  }
  onChoose(item: any, index: number) {
    this.selectedIndex = index;
    this.onChooseSelect(item.name, item.value, index);
    this.onCloseSelect();
  }
  selectedIndex = -1;
  onKeyDown(e: any) {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      if (e.key === "ArrowDown") {
        this.selectedIndex += 1;
      }
      if (e.key === "ArrowUp") {
        this.selectedIndex -= 1;
      }
      if (this.selectedIndex === this.dataSelect?.length) {
        this.selectedIndex = 0;
      }
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.dataSelect?.length - 1;
      }
      this.onChooseSelect(
        this.dataSelect[this.selectedIndex].name,
        this.dataSelect[this.selectedIndex].value
      );
    }
    if (e.key === "Enter") {
      this.onCloseSelect();
    }
  }

  openMenu() {
    this.$emit("openMenu", 0);
  }

  onCloseAnywhere() {
    this.isSelectOpen = false;
    this.$emit("closeMenu");
  }

  get dataOptionsList() {
    return this.isAddableListNumber ? this.newDataSelect : this.dataSelect;
  }

  newDataSelect: Array<any> = [];
  assignNewDataSelect() {
    this.dataSelect.forEach((item: any) => {
      this.newDataSelect.push({
        name: item.name,
        value: item.value,
        isDeleteable: false
      });
    });
  }
  addListIncreament() {
    const lastValue = this.newDataSelect[this.newDataSelect.length - 1];
    this.newDataSelect.push({
      name: String(Number(lastValue.name) + 1),
      value: String(Number(lastValue.value) + 1),
      isDeleteable: true
    });
    this.openSelect();
  }

  deleteItemFromList() {
    this.newDataSelect.pop();
    this.openSelect();
  }
}
